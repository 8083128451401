import $ from 'jquery'

class Offcanvas {
    constructor() {
        this.body = $('body')
        this.offcanvasEl = $('.c-offcanvas')
        this.menuEl = this.offcanvasEl.find('.c-nav-primary')
        this.subMenus = this.menuEl.find('.sub-menu')
        this.menuItemsWithChildren = this.menuEl.find('.menu__item--has-children > a')
        this.close = this.menuEl.find('.c-offcanvas__close')

        this.createBackButtons()

        // Handle clicking a menu item with children
        this.menuItemsWithChildren.on('click', this.revealSubMenu)

        // Handle clicking a sub menu back button
        this.menuEl.on('click', '.menu__button', this.hideSubMenu)

        // Handle clicking the offcanvas close button
        this.offcanvasEl.on('click', '.c-offcanvas__close', () => this.resetState())
    }

    revealSubMenu(event) {
        event.preventDefault()
        $('.sub-menu').removeClass('is-active')
        let menuItem = $(event.target).parent()
        menuItem.find('> .sub-menu').addClass('is-active')
    }

    hideSubMenu(event) {
        event.preventDefault()
        let subMenu = $(event.target).parent()
        subMenu.removeClass('is-active')
    }

    createBackButtons() {
        this.subMenus.each(function () {
            $(this).prepend('<button class="menu__button"/>')
        })
    }

    resetState() {
        this.body.removeClass('navigation--is-active')
        this.subMenus.removeClass('is-active')

    }
}

export default Offcanvas