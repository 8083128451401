import $ from 'jquery'

class Book {
    constructor() {
        this.header_book = $('.c-page-header__book');
        this.mobile_book = $('.c-page-footer__book');
        this.close_booking = $('.c-booking-section__close');
        this.events();
    }

    events() {
        this.header_book.on('click', this.toggleBooking);
        this.mobile_book.on('click', this.toggleBooking);
        this.close_booking.on('click', this.closeBooking);
    }

    toggleBooking(e){
        e.preventDefault();
        this.body = $('body');
        this.body.toggleClass('booking-is-active');
    }

    closeBooking(){
        this.body = $('body');
        this.body.removeClass('booking-is-active');
    }
}

export default Book